@font-face{
  font-family: psr;
  src: url(https://cdn.rawgit.com/AdithyaBhat17/zen.io/gh-pages/static/media/psr.eae9c18c.ttf);
}

body {
  margin: 0;
  padding: 0;
  font-family: psr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}


.btn{
  display: block;
  margin: 5px auto;
}

h1{
  font-weight: bold;
}

.info{
  /* display: flex; */
  margin: 25px auto !important;
}

.links,.links:hover,.links:focus{
  text-decoration: none !important;
  color: #333333;
}

.links>img{
  border-radius: 50%;
  box-shadow: 5px 5px 25px rgba(0,0,0,0.2);
  width: 200px;
  margin: 5px auto;
}

.links>strong{
  font-size: 1.4em;
  text-align: left;
}

small{
  font-weight: lighter;
}

header{
  background-color: #333333;
  color: #fff;
  padding: 2px;
}

.user-details{
  list-style-type: none;
  display: inline-flex;
  justify-content: space-around;
  align-content: space-around;
}

.avatar-follower{
  border-radius: 50%;
  box-shadow: 5px 5px 25px rgba(0,0,0,0.2);
  width: 50px;
  margin: 5px auto;
}

.thumbnail{
  display: block;
  margin: 10px auto;
  width: 30%;
  border: 0;
  box-shadow: 5px 5px 25px rgba(0,0,0,0.2);
  color: #191586;  
}

.search-img{
  width: 60%;
}

.thumbnail-2{
  display: block;
  margin: 10px auto;
  width: 40%;
  border: 0;
  box-shadow: 5px 5px 25px rgba(0,0,0,0.2);
  color: #333;  
  padding: 5px;
  font-weight: bold;
}

.purple{
  color: #191586
}

.name-follower{
  margin: 20px auto;
}

.loading>img{
  width: 60%;
}

.error-img{
  margin: 15px auto;
  width: 40%;
}

@media screen and (max-width:768px){
  .thumbnail{
    width: 70%;
  }
  .thumbnail-2{
    width: 100%;
  }
  h4{
    margin-top:15vh;
  }
  .search-img{
    width: 100%;
  }
  .error-img{
    margin: 15px auto;
    width: 80%;
  }
}



